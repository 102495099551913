import React from "react"
import IconCheck from "../../assets/svg/icons/check.svg"

const Success = () => (
  <div className="d-flex flex-column align-items-center text-center py-4">
    <IconCheck className="text-primary mb-4" />
    <h2 className="text-primary mb-4">Thank you!</h2>
    <p className="text-secondary mb-0" style={{ width: "85%" }}>
      Your request has been sent.
      <br />
      We will check it out and get back to you shortly.
    </p>
  </div>
)

export default Success
