import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import $axios from "../../../utils/axios"
import Card from "../Card"
import PageHeader from "../PageHeader"
import Success from "../Success"
import Survey from "../Survey"

const SurveyView = () => {
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const [cookies, setCookie] = useCookies(["registerToken"])

  useEffect(() => {
    $axios()
      .get(`access-request/${cookies["registerToken"]}/`)
      .then(({ data }) => {
        if (data.submitted) {
          navigate("/404")
        }

        setEmail(data.email)
      })
      .catch(() => {
        navigate("/404")
      })
  }, [])

  const handleSuccess = () => {
    // setSuccess(true)
    navigate("/thanks")
  }

  return (
    <>
      {!success ? (
        <PageHeader
          title="Lumis.ai is invite only"
          subtitle="You can request access by entering your email"
        />
      ) : (
        <PageHeader title="Lumis.ai" />
      )}
      <Card
        footer={
          success && (
            <>
              You already have an access code?{" "}
              <a href="https://account.lumis.ai/access/" target="_blank" rel="noopener noreferrer">
                Click here
              </a>{" "}
              to get started.
            </>
          )
        }
      >
        {!success ? (
          <Survey token={cookies["registerToken"]} email={email} onSuccess={handleSuccess} />
        ) : (
          <Success />
        )}
      </Card>
    </>
  )
}

export default SurveyView
