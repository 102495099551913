import { Form as FormikForm, Formik } from "formik"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Spinner from "react-bootstrap/Spinner"
import $axios from "../../utils/axios"
import { maybe } from "../../utils/misc"

const Survey = ({ token, email, onSuccess }) => {
  const [validation, setValidation] = useState()

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    $axios()
      .post(`access-request/${token}/survey/`, values)
      .then(() => {
        onSuccess()
      })
      .catch(error => {
        const code = parseInt(error.response && error.response.status)
        if (code === 400) {
          setValidation(error.response.data)
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Formik
      initialValues={{ fullName: "", people: "", ticketPrice: "", hearing: "", interest: "" }}
      onSubmit={handleSubmit}
    >
      {({ handleChange, values, isSubmitting }) => (
        <FormikForm noValidate>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control value={email} disabled />
          </Form.Group>
          <Form.Group>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              name="fullName"
              value={values.fullName}
              onChange={handleChange}
              isInvalid={!!maybe(() => validation.fullName)}
              autoFocus
            />
            <Form.Control.Feedback type="invalid">
              {maybe(() => validation.fullName)}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>How many people come to your event?</Form.Label>
            <Form.Control
              name="people"
              value={values.people}
              onChange={handleChange}
              isInvalid={!!maybe(() => validation.people)}
            />
            <Form.Control.Feedback type="invalid">
              {maybe(() => validation.people)}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>What is an average ticket price?</Form.Label>
            <Form.Control
              name="ticketPrice"
              value={values.ticketPrice}
              onChange={handleChange}
              isInvalid={!!maybe(() => validation.ticketPrice)}
            />
            <Form.Control.Feedback type="invalid">
              {maybe(() => validation.ticketPrice)}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>How did your hear about Lumis.ai?</Form.Label>
            <Form.Control
              name="hearing"
              value={values.hearing}
              onChange={handleChange}
              isInvalid={!!maybe(() => validation.hearing)}
            />
            <Form.Control.Feedback type="invalid">
              {maybe(() => validation.hearing)}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Why are you interested in Lumis.ai?</Form.Label>
            <Form.Control
              as="textarea"
              rows="2"
              name="interest"
              value={values.interest}
              onChange={handleChange}
              isInvalid={!!maybe(() => validation.interest)}
            />
            <Form.Control.Feedback type="invalid">
              {maybe(() => validation.interest)}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            type="submit"
            disabled={isSubmitting}
            className="d-flex align-items-center justify-content-center w-100"
          >
            {isSubmitting && <Spinner animation="border" size="sm" className="mr-3" />}
            Send Request
          </Button>
        </FormikForm>
      )}
    </Formik>
  )
}

export default Survey
