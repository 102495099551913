import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"

const NavLink = ({ link, linkText }) => (
  <Nav.Item>
    <Nav.Link as={Link} to={link} className="small text-muted">
      {linkText}
    </Nav.Link>
  </Nav.Item>
)

const Footer = () => (
  <footer className="py-8 mt-auto">
    <Container>
      <Row className="mb-3">
        <Col>
          <Nav className="justify-content-center">
            <NavLink link="/privacy" linkText="Privacy Policy" />
            <NavLink link="/terms" linkText="Terms &amp; Conditions" />
            <NavLink link="/imprint" linkText="Imprint" />
          </Nav>
        </Col>
      </Row>
      <Row className="text-center">
        <Col>
          <small className="text-muted">
            &copy; {new Date().getFullYear()} Lumis. All rights reserved.
          </small>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
