import React from "react"

const PageHeader = ({ title, subtitle }) => (
  <div className="mb-8">
    <h1 className="text-center mb-2">{title}</h1>
    {subtitle && <p className="lead text-center text-secondary mb-0">{subtitle}</p>}
  </div>
)

export default PageHeader
