import React from "react"
import Card from "../Card"
import PageHeader from "../PageHeader"
import RequestAccess from "../RequestAccess"

const RequestAccessView = () => (
  <>
    <PageHeader
      title="Lumis.ai is invite only"
      subtitle="You can request access by entering your email"
    />
    <Card
      footer={
        <>
          You already have an access code?{" "}
          <a href="https://account.lumis.ai/access/" target="_blank" rel="noopener noreferrer">
            Click here
          </a>{" "}
          to get started.
        </>
      }
    >
      <RequestAccess />
    </Card>
  </>
)

export default RequestAccessView
