import { Form as FormikForm, Formik } from "formik"
import { navigate } from "gatsby"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Spinner from "react-bootstrap/Spinner"
import { useCookies } from "react-cookie"
import $axios from "../../utils/axios"
import { maybe } from "../../utils/misc"

const RequestAccess = ({ className }) => {
  const [cookies, setCookie] = useCookies(["registerToken"])
  const [validation, setValidation] = useState()

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    $axios()
      .post("access-request/", values)
      .then(({ data }) => {
        setCookie("registerToken", data.token, {
          path: "/",
          expires: new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000),
        })
        navigate(`/start-register`)
      })
      .catch(error => {
        const code = parseInt(error.response && error.response.status)
        if (code === 400) {
          setValidation(error.response.data)
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }
  const handleClick = () => {
    window.analytics.track("Entered Email")
  }

  return (
    <Formik initialValues={{ email: "" }} onSubmit={handleSubmit}>
      {({ handleChange, values, isSubmitting }) => (
        <FormikForm noValidate>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              value={values.email}
              onChange={handleChange}
              isInvalid={!!maybe(() => validation.email)}
              type="email"
              placeholder="john.doe@gmail.com"
              autoFocus
            />
            <Form.Control.Feedback type="invalid">
              {maybe(() => validation.email)}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            onClick={handleClick}
            type="submit"
            disabled={isSubmitting}
            className="d-flex align-items-center justify-content-center w-100"
          >
            {isSubmitting && <Spinner animation="border" size="sm" className="mr-3" />}
            Request Access
          </Button>
        </FormikForm>
      )}
    </Formik>
  )
}

export default RequestAccess
