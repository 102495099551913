import React from "react"
import BCard from "react-bootstrap/Card"

const Card = ({ children, footer }) => (
  <BCard className="fadeInDown animationDelay-3 shadow-sm">
    <BCard.Body className="p-7">{children}</BCard.Body>
    {footer && (
      <div class="border-top text-center p-4">
        <p className="text-secondary mb-0">{footer}</p>
      </div>
    )}
  </BCard>
)

export default Card
